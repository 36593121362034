import React from "react";
import { Container, Table } from "react-bootstrap";
import HaderContent2 from "../Commponent/HaderContent2";

const InvestorResponsibilities = () => {
    return (
        <React.Fragment>
            <HaderContent2 Title="Responsibilities of Investors" SubTitle="Investor Responsibilities and Best Practices" />
            <br />
            <Container>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Responsibilities</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>i.</td>
                            <td>Deal with a SEBI registered DP for opening demat account, KYC, and Depository activities.</td>
                        </tr>
                        <tr>
                            <td>ii.</td>
                            <td>Provide complete documents for account opening and KYC (Know Your Client).</td>
                        </tr>
                        <tr>
                            <td>iii.</td>
                            <td>Fill all the required details in Account Opening Form/KYC form in your own handwriting and cancel out any blanks.</td>
                        </tr>
                        <tr>
                            <td>iv.</td>
                            <td>Read all documents and conditions being agreed upon before signing the account opening form.</td>
                        </tr>
                        <tr>
                            <td>v.</td>
                            <td>
                                Accept the Delivery Instruction Slip (DIS) book from your DP only (pre-printed with a serial number along with your Client ID)
                                and keep it in safe custody. Do not sign or issue blank or partially filled DIS.
                            </td>
                        </tr>
                        <tr>
                            <td>vi.</td>
                            <td>Always mention the details like ISIN and the number of securities accurately.</td>
                        </tr>
                        <tr>
                            <td>vii.</td>
                            <td>Inform any change in information linked to your demat account and obtain confirmation of the update in the system.</td>
                        </tr>
                        <tr>
                            <td>viii.</td>
                            <td>Regularly verify balances and demat statements and reconcile them with trades/transactions.</td>
                        </tr>
                        <tr>
                            <td>ix.</td>
                            <td>Appoint nominee(s) to facilitate heirs in obtaining the securities in their demat account.</td>
                        </tr>
                        <tr>
                            <td>x.</td>
                            <td>
                                Do not fall prey to fraudsters sending emails and SMSs luring you to trade in stocks/securities, promising huge profits.
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    );
};

export default InvestorResponsibilities;
