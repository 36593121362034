import React from "react";
import { Container, Table } from "react-bootstrap";
import HaderContent2 from "../Commponent/HaderContent2";

const CodeOfConductDepositories = () => {
    return (
        <React.Fragment>
            <HaderContent2 Title="Code of Conduct for Depositories" SubTitle="Depository Code of Conduct" />
            <Container>
                <br />
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Code of Conduct</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>(a)</td>
                            <td>Always abide by the provisions of the Act, Depositories Act, 1996, any Rules or Regulations framed thereunder, circulars, guidelines, and any other directions issued by the Board from time to time.</td>
                        </tr>
                        <tr>
                            <td>(b)</td>
                            <td>Adopt appropriate due diligence measures.</td>
                        </tr>
                        <tr>
                            <td>(c)</td>
                            <td>Take effective measures to ensure implementation of proper risk management framework and good governance practices.</td>
                        </tr>
                        <tr>
                            <td>(d)</td>
                            <td>Take appropriate measures towards investor protection and education of investors.</td>
                        </tr>
                        <tr>
                            <td>(e)</td>
                            <td>Treat all its applicants/members in a fair and transparent manner.</td>
                        </tr>
                        <tr>
                            <td>(f)</td>
                            <td>Promptly inform the Board of violations of the provisions of the Act, the Depositories Act, 1996, rules, regulations, circulars, guidelines or any other directions by any of its issuers or issuer’s agent.</td>
                        </tr>
                        <tr>
                            <td>(g)</td>
                            <td>Take a proactive and responsible attitude towards safeguarding the interests of investors, the integrity of depository’s systems, and the securities market.</td>
                        </tr>
                        <tr>
                            <td>(h)</td>
                            <td>Endeavor to introduce best business practices amongst itself and its members.</td>
                        </tr>
                        <tr>
                            <td>(i)</td>
                            <td>Act in utmost good faith and avoid conflict of interest in the conduct of its functions.</td>
                        </tr>
                        <tr>
                            <td>(j)</td>
                            <td>Not indulge in unfair competition, which is likely to harm the interests of any other Depository, participants, or investors, or is likely to place them in a disadvantageous position while competing for or executing any assignment.</td>
                        </tr>
                        <tr>
                            <td>(k)</td>
                            <td>
                                Segregate roles and responsibilities of key management personnel within the depository, including:
                                <ul>
                                    <li>Clearly mapping legal and regulatory duties to the concerned position.</li>
                                    <li>Defining delegation of powers to each position.</li>
                                    <li>Assigning regulatory, risk management, and compliance aspects to business and support teams.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>(l)</td>
                            <td>Be responsible for the acts or omissions of its employees in respect of the conduct of its business.</td>
                        </tr>
                        <tr>
                            <td>(m)</td>
                            <td>Monitor compliance of the rules and regulations by participants and further ensure that their conduct safeguards the interest of investors and the securities market.</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    );
};

export default CodeOfConductDepositories;
