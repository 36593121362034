import React from "react";
import { Container, Table } from "react-bootstrap";
import HaderContent2 from "../Commponent/HaderContent2";

const InvestorGuidelines = () => {
    return (
        <React.Fragment>
            <HaderContent2 Title="Dos and Don'ts for Investors" SubTitle="Guidelines for Safe Investments" />
            <Container>
                <br />

                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Guidance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>i.</td>
                            <td>Always deal with a SEBI registered Depository Participant for opening a demat account.</td>
                        </tr>
                        <tr>
                            <td>ii.</td>
                            <td>Read all the documents carefully before signing them.</td>
                        </tr>
                        <tr>
                            <td>iii.</td>
                            <td>
                                Before granting Power of attorney to operate your demat account to an intermediary like Stock Broker, Portfolio
                                Management Services (PMS) etc., carefully examine the scope and implications of powers being granted.
                            </td>
                        </tr>
                        <tr>
                            <td>iv.</td>
                            <td>Always make payments to registered intermediary using banking channels. No payment should be made in name of employee of intermediary.</td>
                        </tr>
                        <tr>
                            <td>v.</td>
                            <td>
                                Accept the Delivery Instruction Slip (DIS) book from your DP only (pre-printed with a serial number along with your Client ID) and
                                keep it in safe custody. Do not sign or issue blank or partially filled DIS slips. Always mention the details like ISIN, number of
                                securities accurately. In case of any queries, contact your DP or broker. Strike out any blank space on the slip and initial or
                                sign any cancellations or corrections by all account holders. Do not leave your instruction slip book with anyone else or sign blank DIS.
                            </td>
                        </tr>
                        <tr>
                            <td>vi.</td>
                            <td>Inform any change in your Personal Information linked to your demat account and obtain confirmation of the update.</td>
                        </tr>
                        <tr>
                            <td>vii.</td>
                            <td>Mention your Mobile Number and email ID in account opening form to receive SMS alerts and updates directly from the depository.</td>
                        </tr>
                        <tr>
                            <td>viii.</td>
                            <td>Ensure your mobile number and email ID linked to your demat account match those provided at the time of account opening/updation.</td>
                        </tr>
                        <tr>
                            <td>ix.</td>
                            <td>Do not share the password of your online trading and demat account with anyone.</td>
                        </tr>
                        <tr>
                            <td>x.</td>
                            <td>Do not share One Time Password (OTP) received from banks, brokers, etc. These are meant to be used by you only.</td>
                        </tr>
                        <tr>
                            <td>xi.</td>
                            <td>Do not share login credentials of e-facilities such as e-DIS/demat gateway, SPEED-e/easiest, etc., with anyone else.</td>
                        </tr>
                        <tr>
                            <td>xii.</td>
                            <td>Demat is mandatory for any transfer of securities of Listed public limited companies.</td>
                        </tr>
                        <tr>
                            <td>xiii.</td>
                            <td>
                                If you have any grievance in respect of your demat account, write to designated email IDs of depositories or lodge a complaint with SEBI
                                at <a href="https://scores.sebi.gov.in" target="_blank" rel="noopener noreferrer">https://scores.sebi.gov.in</a>.
                            </td>
                        </tr>
                        <tr>
                            <td>xiv.</td>
                            <td>Keep a record of documents signed, DIS issued, and account statements received.</td>
                        </tr>
                        <tr>
                            <td>xv.</td>
                            <td>Verify the transaction statement carefully for all debits and credits. Inform your DP or Depository of any unauthorized debit or credit.</td>
                        </tr>
                        <tr>
                            <td>xvi.</td>
                            <td>Appoint a nominee to facilitate your heirs in obtaining the securities in your demat account upon completion of the necessary procedures.</td>
                        </tr>
                        <tr>
                            <td>xvii.</td>
                            <td>Register for Depository's internet-based facility or mobile app to monitor your holdings.</td>
                        </tr>
                        <tr>
                            <td>xviii.</td>
                            <td>
                                Ensure that both your holding and transaction statements are received periodically as instructed to your DP. You are entitled to
                                receive a transaction statement every month if you have transactions.
                            </td>
                        </tr>
                        <tr>
                            <td>xix.</td>
                            <td>Do not follow herd mentality for investments. Seek expert and professional advice for your investments.</td>
                        </tr>
                        <tr>
                            <td>xx.</td>
                            <td>Beware of assured/fixed returns.</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    );
};

export default InvestorGuidelines;
