import React from "react";
import { Container, Table } from "react-bootstrap";
import HaderContent2 from "../Commponent/HaderContent2";

const CodeOfConductParticipants = () => {
    return (
        <React.Fragment>
            <HaderContent2 Title="Code of Conduct for Participants" SubTitle="Participant Code of Conduct" />
            <Container>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Code of Conduct</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>A participant shall make all efforts to protect the interests of investors.</td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>
                                A participant shall always endeavour to:
                                <ul>
                                    <li>Render the best possible advice to clients, considering the client's needs and the environment, as well as the participant’s professional skills.</li>
                                    <li>Ensure that all professional dealings are effected in a prompt, effective, and efficient manner.</li>
                                    <li>Adequately address inquiries from investors.</li>
                                    <li>Ensure grievances of investors are redressed without any delay.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Maintain high standards of integrity in all dealings with clients and intermediaries.</td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>Be prompt and diligent in opening beneficial owner accounts, processing dematerialization and rematerialization requests, and executing debit instruction slips.</td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>Resolve all complaints against the participant within one month of receipt.</td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Provide advance notice before increasing charges/fees for services rendered.</td>
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td>Refrain from unfair competition that may harm other participants or investors.</td>
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td>Avoid making exaggerated statements about qualifications, services, or achievements.</td>
                        </tr>
                        <tr>
                            <td>9.</td>
                            <td>Maintain client confidentiality and only disclose information with authorization or under legal obligations.</td>
                        </tr>
                        <tr>
                            <td>10.</td>
                            <td>Cooperate with the Board when required.</td>
                        </tr>
                        <tr>
                            <td>11.</td>
                            <td>Maintain required knowledge, competency, and compliance with all regulations.</td>
                        </tr>
                        <tr>
                            <td>12.</td>
                            <td>Ensure that all statements or documents submitted to the Board are accurate and truthful.</td>
                        </tr>
                        <tr>
                            <td>13.</td>
                            <td>Promptly submit books, documents, and reports to the Board or other agencies upon request.</td>
                        </tr>
                        <tr>
                            <td>14.</td>
                            <td>Inform the Board promptly of any legal actions or material breaches of law or regulations.</td>
                        </tr>
                        <tr>
                            <td>15.</td>
                            <td>Maintain proper systems for handling incoming mail in all formats.</td>
                        </tr>
                        <tr>
                            <td>16.</td>
                            <td>Implement a maker-checker system to ensure accuracy and prevent unauthorized transactions.</td>
                        </tr>
                        <tr>
                            <td>17.</td>
                            <td>Ensure continuity in data and record-keeping, with up-to-date backups available for electronic records.</td>
                        </tr>
                        <tr>
                            <td>18.</td>
                            <td>Grant adequate freedom and powers to the compliance officer for effective performance of duties.</td>
                        </tr>
                        <tr>
                            <td>19.</td>
                            <td>Ensure satisfactory internal control procedures and operational capabilities to safeguard against fraud and misconduct.</td>
                        </tr>
                        <tr>
                            <td>20.</td>
                            <td>Hold responsibility for the acts or omissions of its employees and agents in business conduct.</td>
                        </tr>
                        <tr>
                            <td>21.</td>
                            <td>Ensure that senior management has timely access to relevant business information.</td>
                        </tr>
                        <tr>
                            <td>22.</td>
                            <td>Implement good corporate policies and corporate governance practices.</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    );
};

export default CodeOfConductParticipants;
