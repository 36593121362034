import React from "react";
import { Container, Table } from "react-bootstrap";
import HaderContent2 from "../Commponent/HaderContent2";

const InvestorRights = () => {
    return (
        <React.Fragment>
            <HaderContent2 Title="Rights of Investors" SubTitle="Investor Rights and Protections" />
            <br />
            <Container>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Rights</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>i.</td>
                            <td>Receive a copy of KYC, copy of account opening documents.</td>
                        </tr>
                        <tr>
                            <td>ii.</td>
                            <td>No minimum balance is required to be maintained in a demat account.</td>
                        </tr>
                        <tr>
                            <td>iii.</td>
                            <td>No charges are payable for opening of demat accounts.</td>
                        </tr>
                        <tr>
                            <td>iv.</td>
                            <td>
                                If executed, receive a copy of Power of Attorney. However, Power of Attorney is not mandatory as per SEBI/Stock Exchanges.
                                You have the right to revoke any authorization given at any time.
                            </td>
                        </tr>
                        <tr>
                            <td>v.</td>
                            <td>You can open more than one demat account in the same name with a single DP/multiple DPs.</td>
                        </tr>
                        <tr>
                            <td>vi.</td>
                            <td>
                                Receive statements of accounts periodically. In case of any discrepancies in statements, take up the matter with the DP
                                immediately. If the DP does not respond, take it up with the Depositories.
                            </td>
                        </tr>
                        <tr>
                            <td>vii.</td>
                            <td>Pledge and/or any other interest or encumbrance can be created on demat holdings.</td>
                        </tr>
                        <tr>
                            <td>viii.</td>
                            <td>Right to give standing instructions regarding the crediting of securities in a demat account.</td>
                        </tr>
                        <tr>
                            <td>ix.</td>
                            <td>
                                Investor can exercise their right to freeze/defreeze their demat account or specific securities/quantity of securities
                                in the account, maintained with the DP.
                            </td>
                        </tr>
                        <tr>
                            <td>x.</td>
                            <td>
                                In case of any grievances, the investor has the right to approach the Participant, Depository, or SEBI to resolve the issue
                                within the prescribed timelines.
                            </td>
                        </tr>
                        <tr>
                            <td>xi.</td>
                            <td>
                                Every eligible investor shareholder has the right to cast their vote on resolutions proposed by companies through
                                the Depositories' internet-based ‘e-Voting’ platform.
                            </td>
                        </tr>
                        <tr>
                            <td>xii.</td>
                            <td>
                                Receive information about charges and fees. Any agreed-upon charges/tariff shall not increase unless a written notice
                                of not less than thirty days is given to the investor.
                            </td>
                        </tr>
                        <tr>
                            <td>xiii.</td>
                            <td>Right to indemnification for any loss caused due to the negligence of the Depository or the participant.</td>
                        </tr>
                        <tr>
                            <td>xiv.</td>
                            <td>Right to opt out of the Depository system for any security.</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    );
};

export default InvestorRights;
